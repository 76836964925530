
.visualization-like-list-container {
  height: 94.5%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  .like-list-tag {
    display: flex;
    margin: 20px 0 27px 21px;
    .like-list-tag-title {
      flex: 1;
      .like-list-img {
        margin-right: 11px;
      }
      .like-list-name {
        font-size: 16px;
        color: #fff;
      }
    }
    .like-list-label {
      margin-right: 19px;
      .like-list-name {
        font-size: 16px;
        color: #ffffff;
      }
      .like-list-num {
        font-size: 16px;
        color: #4b45ff;
      }
    }
  }
  .like-list-echarts {
    margin-bottom: 17px;
    bottom: 15%;
  }
}
