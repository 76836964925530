
.data-visualization {
  background: rgba(7, 9, 50, 1);
  background-size: cover;
  padding: 10px 10px 10px 8px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  .data-visualization-left-container {
    display: flex;
    flex-direction: column;
    width: 28%;
    margin-right: 11px;
  }
  .data-visualization-center-container {
    display: flex;
    flex-direction: column;
    width: 44%;
  }
  .data-visualization-right-container {
    display: flex;
    flex-direction: column;
    width: 27%;
    margin-left: 10px;
  }
}
